import { Moment } from 'moment';

export const formatToLongDate = (date: Moment, locale: string) => {
  const d = date.clone();
  if (locale === 'en-US' || locale === 'en-GB') {
    return d.locale(locale).format('dddd, MM/DD/YYYY');
  }
  return d.locale(locale).format('dddd, DD.MM.YYYY');
};

export const formatToShortDate = (date: Moment, locale: string) => {
  const d = date.clone();
  if (locale.startsWith('en')) {
    return d.locale(locale).format('dd, MM/DD/YYYY');
  }
  return d.locale(locale).format('dd, DD.MM.YYYY');
};

export const formatTime = (date: Moment, locale: string) => {
  const d = date.clone();
  const format = locale === 'en-US' || locale === 'en-GB' ? 'hh:mm A' : 'HH:mm';
  return d.format(format);
};
