import { BvToast } from 'bootstrap-vue/src/components/toast';

export enum ToastVariants {
  SUCCESS = 'success',
  DANGER = 'danger',
}

export const toast = (bvToast: BvToast, message: string, variant = ToastVariants.SUCCESS, options = {}) => {
  bvToast.toast(message, {
    toastClass: `tg-toaster-${variant} tg-toaster`,
    toaster: 'b-toaster-bottom-center',
    autoHideDelay: 3000,
    appendToast: true,
    variant,
    solid: true,
    noCloseButton: true,
    ...options,
  });
};

export default toast;
