
import Vue, { PropType } from 'vue';
import moment from 'moment';

export default Vue.extend({
  name: 'MoleculeDateSection',
  props: {
    date: {
      type: Object as PropType<moment.Moment>,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getDate() {
      const momentDate = moment(this.date);
      if (momentDate.isSame(moment(), 'day')) {
        return (
          this.$t('dictionary.today') + momentDate.locale(this.$i18n.locale).format(', DD.MM.')
        );
      }
      return momentDate.locale(this.$i18n.locale).format('dddd, DD.MM.');
    },
  },
});
