
import { defineComponent } from 'vue';
import i18n from '@/i18n';
import TemplateSharedRides from '@/components/templates/rides/TemplateSharedRides.vue';

export default defineComponent({
  name: 'SharedRides',
  components: { TemplateSharedRides },
  metaInfo: { title: ` - ${i18n.t('tabs.sharedRides')}` },
});
