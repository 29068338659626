
import Vue, { PropType } from 'vue';
import { BCard } from 'bootstrap-vue';

export enum CardVariant {
  BASIC = 'tg-basic',
  PRIMARY = 'tg-primary',
  SECONDARY = 'tg-secondary',
  INLINE = 'tg-inline',
}

export default Vue.extend({
  name: 'MoleculeCard',
  components: {
    BCard,
  },
  props: {
    variant: {
      type: String as PropType<CardVariant>,
      required: false,
      validator: (val: CardVariant) => Object.values(CardVariant).includes(val),
      default: CardVariant.BASIC,
    },
  },
  computed: {
    hasHeaderSlot(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return !!this.$slots.header;
    },
    hasFooterSlot(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return !!this.$slots.footer;
    },
    getClasses() {
      return {
        'card-container': true,
        [this.variant]: true,
      };
    },
    hasClickListener(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return this.$listeners && this.$listeners.click && true;
    },
  },
});
