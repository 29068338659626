
import Vue from 'vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export default Vue.extend({
  name: 'OrganismPageSubHeader',
  components: {
    AtomSvgIcon,
  },
  data: () => ({
    isSubheaderFixed: false,
  }),
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    isIconClickable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    handleScroll(): (this: GlobalEventHandlers, ev: Event) => any | null {
      const SUBHEADER_HEIGHT_PX = 72;
      const navbar = document.querySelector('.page-sub-header-wrapper-container');
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      if (!navbar) return () => {};

      const { top } = navbar.getBoundingClientRect();
      const offset = top < 0 ? SUBHEADER_HEIGHT_PX : top;
      const scroll = () => {
        if (window.scrollY > offset) {
          this.isSubheaderFixed = true;
        } else {
          this.isSubheaderFixed = false;
        }
      };

      return scroll;
    },
  },
  mounted() {
    document.body.onscroll = this.handleScroll();
  },
  destroyed() {
    document.body.onscroll = null;
  },
});
