
import Vue, { PropType } from 'vue';
import moment from 'moment';
import { formatTime } from '@/common/DateFormatter';
import { decodeString } from '@/common/StringDecoder';

export default Vue.extend({
  name: 'AtomCardWaypoint',
  methods: {
    formatTime,
    decodeString,
  },
  props: {
    location: {
      type: String,
      required: true,
    },
    time: {
      type: Object as PropType<moment.Moment>,
      required: true,
    },
    last: {
      type: Boolean,
      default: false,
    },
  },
});
